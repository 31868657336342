import React from 'react';
import NumberInput from './NumberInput';

const YearInput = ({ placeholderText, minVal = 1, maxVal = Number.MAX_SAFE_INTEGER, inputChangeCallback }) => {
    return <NumberInput
        classNames={["year-input"]}
        placeholderText={placeholderText}
        minVal={minVal}
        maxVal={maxVal}
        inputChangeCallback={inputChangeCallback}
    />;
};

export default YearInput;
