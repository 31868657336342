import React, { useState, useEffect } from 'react';

const DateRangeEditor = () => {
    const [dateRanges, setDateRanges] = useState({});
    const [loading, setLoading] = useState(true);

    const getDateRanges = () => {
        jQuery.ajax({
            dataType: 'json',
            url: jdsAjax.ajaxUrl,
            data: {action: 'get_date_ranges'},
            success: (response) => {
                setLoading(false);
                setDateRanges(response);
            }
        });
    };

    useEffect(() => {
        getDateRanges();
    }, []);

    const [textInputShowing, setTextInputShowing] = useState(false);

    const handleClickAddDateRange = () => {
        setTextInputShowing(true);
    };

    const [startMonth, setStartMonth] = useState('');

    const handleStartMonthInputChange = ({ target }) => {
        setStartMonth(target.value);
    };

    const [startYear, setStartYear] = useState('');

    const handleStartYearInputChange = ({ target }) => {
        setStartYear(target.value);
    };

    const [endMonth, setEndMonth] = useState('');

    const handleEndMonthInputChange = ({ target }) => {
        setEndMonth(target.value);
    };

    const [endYear, setEndYear] = useState('');

    const handleEndYearInputChange = ({ target }) => {
        setEndYear(target.value);
    };

    const [cfFile, setCfFile] = useState(null);

    const handleFileInputChange = ({ target }) => {
        setCfFile(target.files[0]);
    };

    const handleClickAdd = () => {
        setLoading(true);
        setTextInputShowing(false);

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            jQuery.ajax({
                dataType: 'text',
                url: jdsAjax.ajaxUrl,
                data: {
                    action: 'add_date_range',
                    jds_start_month: startMonth,
                    jds_start_year: startYear,
                    jds_end_month: endMonth,
                    jds_end_year: endYear,
                    jds_cf_file_contents: reader.result
                },
                success: (_response) => {
                    setCfFile(null);
                    getDateRanges();
                }
            });
        });

        reader.readAsText(cfFile);
    };

    const handleClickRemove = (index) => {
        setLoading(true);

        jQuery.ajax({
            dataType: 'text',
            url: jdsAjax.ajaxUrl,
            data: {action: 'remove_date_range', jds_index_of_date_range_to_remove: index},
            success: (_response) => {
                getDateRanges();
            }
        });
    };

    return (
        <div className="card date-range-editor">
            <h2>Date Ranges</h2>

            {
                loading ?
                    <p>Loading...</p> :
                    (
                        0 === Object.keys(dateRanges).length ?
                            <p>No date ranges to display</p> :
                            <div className="container-date-ranges">
                                {
                                    dateRanges.map((dateRange, i) =>
                                        <div className="container-date-range">
                                            <p>{dateRange.name}</p>

                                            <button
                                                className="button-secondary"
                                                onClick={() => handleClickRemove(i)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                    )
            }

            { 
                textInputShowing ?
                    <div className="add-date-range-form">
                        <div className="date-input-container">
                            <div className="date-input">
                                <label for="jds-date-range-start-month-input">Start month:</label>

                                <input
                                    id="jds-date-range-start-month-input"
                                    type="number"
                                    value={startMonth}
                                    onChange={handleStartMonthInputChange}
                                    min={1}
                                    max={12}
                                />
                            </div>

                            <div className="date-input">
                                <label for="jds-date-range-start-year-input">Start year:</label>

                                <input
                                    id="jds-date-range-start-year-input"
                                    type="number"
                                    value={startYear}
                                    onChange={handleStartYearInputChange}
                                    min={1900}
                                    max={2100}
                                />
                            </div>

                            <div className="date-input">
                                <label for="jds-date-range-end-month-input">End month:</label>

                                <input
                                    id="jds-date-range-end-month-input"
                                    type="number"
                                    value={endMonth}
                                    onChange={handleEndMonthInputChange}
                                    min={1}
                                    max={12}
                                />
                            </div>

                            <div className="date-input">
                                <label for="jds-date-range-end-year-input">End year:</label>

                                <input
                                    id="jds-date-range-end-year-input"
                                    type="number"
                                    value={endYear}
                                    onChange={handleEndYearInputChange}
                                    min={1900}
                                    max={2100}
                                />
                            </div>
                        </div>

                        <div className="file-input-container">
                            <label for="jds-cf-file-input">Conversion factors:</label>

                            <input
                                id="jds-cf-file-input"
                                type="file"
                                accept=".csv" 
                                onChange={handleFileInputChange}
                            />
                        </div>

                        <div>
                            <button className="button-primary" onClick={handleClickAdd}>Add</button>
                        </div>
                    </div> :
                    <button
                        className="button-primary"
                        onClick={handleClickAddDateRange}
                        disabled={loading}
                    >
                        Add a date range
                    </button>
            }
        </div>
    );
};

export default DateRangeEditor;
