import React from 'react';

const TextInput = ({ placeholderText, inputChangeCallback }) => {
    const handleChange = ({ target }) => {
        inputChangeCallback(target.value);
    };

    return <input type="text" placeholder={placeholderText} onChange={handleChange} />;
};

export default TextInput;
