import React, { useState, Children, cloneElement } from 'react';

const Repeat = ({
    children,
    repeatNum,
    removeRepeat,
    removeButtonText,
    formComponentId,
    updateCo2esCallback,
    updateSummaryCallback,
    deleteSummaryCallback,
    updateEmailDataCallback,
    deleteEmailDataCallback,
    repeaterInitiallyEmpty,
    updateCfsCallback
}) => {
    const [dateRangeIndex, setDateRangeIndex] = useState(-1);
    const [summary, setSummary] = useState({});

    const updateDateRange = (val) => {
        setDateRangeIndex(val);
        updateSummaryCallback(summary, val, multiplier, repeatNum);
        
        setEmailData((prevEmailData) => {
            Children.forEach(children, (_child, i) => {
                const childFormComponentId = createChildFormComponentId(i);

                if (prevEmailData[childFormComponentId]) {
                    prevEmailData[childFormComponentId].dateRangeIndex = val;
                }
            });

            updateEmailDataCallback(prevEmailData);

            return prevEmailData;
        });

        updateCfsCallback(cfs, val);
    };

    const [multiplier, setMultiplier] = useState(1);

    const updateMultiplier = (val) => {
        setMultiplier(val);
        updateSummaryCallback(summary, dateRangeIndex, val, repeatNum);
    }

    const updateSummary = (s) => {
        setSummary((prevSummary) => {
            const combinedSummary = Object.assign(prevSummary, s);
            updateSummaryCallback(combinedSummary, dateRangeIndex, multiplier, repeatNum);

            return combinedSummary;
        });
    }

    const [_emailData, setEmailData] = useState({});

    const updateEmailData = (sectionEmailData, sectionTitle, sectionFormComponentId) => {
        if (repeaterInitiallyEmpty) {
            updateEmailDataCallback(sectionEmailData, sectionTitle, sectionFormComponentId);
        } else {
            setEmailData((prevEmailData) => {
                if (!prevEmailData) {
                    prevEmailData = {};
                }
    
                prevEmailData[sectionFormComponentId] = {
                    title: sectionTitle,
                    dateRangeIndex: dateRangeIndex,
                    data: sectionEmailData
                };
    
                updateEmailDataCallback(prevEmailData);
    
                return prevEmailData;
            });
        }
    }

    const [cfs, setCfs] = useState({});

    const updateCf = (cfIndex, val) => {
        setCfs((prevCfs) => {
            prevCfs[cfIndex] = val;
            updateCfsCallback(prevCfs, dateRangeIndex);

            return prevCfs;
        });
    };

    const handleClickRemoveButton = () => {
        deleteSummaryCallback(dateRangeIndex, repeatNum);

        const sectionFormComponentIds = [];

        React.Children.forEach(children, (child, i) => {
            sectionFormComponentIds.push(createChildFormComponentId(i));
        });

        deleteEmailDataCallback(sectionFormComponentIds);
        removeRepeat(repeatNum);
    };

    const createChildFormComponentId = i => formComponentId.toString() + '-' + i.toString();

    return (
        <div className="repeat">
            {Children.map(children, (child, i) => {
                let propsToUpdate = {
                    updateCo2esCallback: updateCo2esCallback,
                    updateSummaryCallback: updateSummary,
                    updateDateRangeCallback: updateDateRange,
                    multiplier: multiplier,
                    updateMultiplierCallback: updateMultiplier,
                    updateEmailDataCallback: updateEmailData,
                    deleteEmailDataCallback: deleteEmailDataCallback,
                    formComponentId: createChildFormComponentId(i),
                    repeatNum: repeatNum,
                    updateCfCallback: updateCf
                };

                if (0 === i) {
                    propsToUpdate.firstInRepeat = true;
                    propsToUpdate.handleClickRemoveRepeatButton = handleClickRemoveButton;
                    propsToUpdate.removeRepeatButtonText = removeButtonText;
                }
                    
                return cloneElement(child, propsToUpdate);
            })}
        </div>
    );
};

export default Repeat;
