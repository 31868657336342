import React, { cloneElement, Children } from 'react';

const RadioGroup = ({ name, children, inputChangeCallback }) => {
    const renderChildren = () => {
        return Children.map(children, (child, i) => {
            return cloneElement(child, {name: name, num: i, inputChangeCallback: inputChangeCallback});
        });
    };
    
    return (
        <div className="radio-group">
            {renderChildren()}
        </div>
    );
};

export default RadioGroup;
