import React, { useState, useEffect } from 'react';
import Form from './containers/form/Form';
import FormStep from './containers/form/FormStep';
import FormPage from './containers/form/FormPage';
import FormSection from './containers/form/FormSection';
import FormField from './containers/form/FormField';
import TextInput from './components/form/TextInput';
import RadioGroup from './components/form/RadioGroup';
import RadioOption from './components/form/RadioOption';
import NumberInput from './components/form/NumberInput';
import PercentageInput from './components/form/PercentageInput';
import LongTextInput from './components/form/LongTextInput';
import YearInput from './components/form/YearInput';
import Repeater from './containers/form/Repeater';
import FileInput from './components/form/FileInput';
import DateRangeInput from './components/form/DateRangeInput';
import Columns from './containers/form/Columns';
import Results from './components/form/Results';

const CarbonCalcForm = () => {
    const [numEvents, setNumEvents] = useState(0);

    const handleNumEventsChange = (val) => {
        setNumEvents(parseInt(val));
    };

    const [numAssets, setNumAssets] = useState(0);

    const handleNumAssetsChange = (val) => {
        setNumAssets(parseInt(val));
    };

    const milesToKm = 1.6093;

    const [dateRanges, setDateRanges] = useState([]);

    useEffect(() => {
        jQuery.ajax({
            dataType: 'json',
            url: jdsAjax.ajaxUrl,
            data: {action: 'get_date_ranges'},
            success: (response) => {
                const loadedDateRanges = [];

                Object.entries(response).forEach(([dateRangeIndex, dateRange]) => {
                    loadedDateRanges[parseInt(dateRangeIndex)] = dateRange;
                });

                setDateRanges(loadedDateRanges);
            }
        });
    }, []);

    const [inputs, setInputs] = useState({});
    const [co2es, setCo2es] = useState({});

    const updateInputsAndCo2es = (summary) => {
        setInputs((prevInputs) => {
            Object.entries(summary).forEach(([aggIndex, summaryEntry]) => {
                const input = {scope: summaryEntry.scope, vals: {}};
                const co2eEntry = {scope: summaryEntry.scope, vals: {}};

                Object.entries(summaryEntry.vals).forEach(([dateRangeIndex, val]) => {
                    if (dateRangeIndex >= 0) {
                        Object.assign(input.vals, {[dateRangeIndex]: parseFloat(val)});

                        Object.assign(
                            co2eEntry.vals,
                            {[dateRangeIndex]: val * dateRanges[dateRangeIndex].conversionFactors[aggIndex]}
                        );
                    }
                });

                prevInputs[aggIndex] = input;

                setCo2es((prevCo2es) => {
                    prevCo2es[aggIndex] = co2eEntry;

                    return prevCo2es;
                });
            });

            return prevInputs;
        });
    };

    const deleteInputsAndCo2es = (dateRangeIndex, aggIndices) => {
        setInputs((prevInputs) => {
            aggIndices.forEach((aggIndex) => {
                delete prevInputs[aggIndex].vals[dateRangeIndex];
            });

            return prevInputs;
        });

        setCo2es((prevCo2es) => {
            aggIndices.forEach((aggIndex) => {
                delete co2es[aggIndex].vals[dateRangeIndex];
            });

            return prevCo2es;
        });
    };

    const [emailData, setEmailData] = useState({});

    const updateEmailData = (formEmailData) => {
        setEmailData(formEmailData);
    };

    const updateCfs = (cfs) => {
        setDateRanges((prevDateRanges) => {
            Object.entries(cfs).forEach(([dateRangeIndex, cfsForDateRange]) => {
                if (prevDateRanges[dateRangeIndex]) {
                    if (prevDateRanges[dateRangeIndex].conversionFactors) {
                        prevDateRanges[dateRangeIndex].conversionFactors = Object.assign(
                            prevDateRanges[dateRangeIndex].conversionFactors,
                            cfsForDateRange
                        );
                    } else {
                        prevDateRanges[dateRangeIndex].conversionFactors = cfsForDateRange;
                    }
                }
            });

            return prevDateRanges;
        });
    };

    return (
        <>
            <h1>Your Carbon Calculator</h1>

            <Form 
                updateCo2esCallback={updateInputsAndCo2es}
                deleteCo2esCallback={deleteInputsAndCo2es}
                updateCfsCallback={updateCfs}
                updateEmailDataCallback={updateEmailData}
                emailDataToSend={emailData}
                dateRanges={dateRanges}
            >
                <FormStep title="General Information">
                    <FormPage title="Let's get started">
                        <FormSection title="Organisation details">
                            <FormField title="Organisation name (client/owner)">
                                <TextInput placeholderText="Organisation details" />
                            </FormField>

                            <FormField title="Organisation address (head quarters / main address)">
                                <TextInput placeholderText="Organisation address" />
                            </FormField>

                            <FormField title="Sector">
                                <RadioGroup name="sector">
                                    <RadioOption text="Private" />
                                    <RadioOption text="Public" />
                                    <RadioOption text="Third" />
                                </RadioGroup>
                            </FormField>

                            <FormField title="Sub-sector">
                                <TextInput placeholderText="Sub-sector" />
                            </FormField>

                            <FormField title="Industry (SIC if available)">
                                <TextInput placeholderText="Industry" />
                            </FormField>

                            <FormField title="How many buildings will be assessed?">
                                <NumberInput
                                    placeholderText="No. of buildings"
                                    minVal={0}
                                    changeHandler={handleNumAssetsChange}
                                />
                            </FormField>

                            <FormField title="Would you like to include events (if yes - check line 72 for data 
                                entry for each event)?">
                                <RadioGroup name="include-events">
                                    <RadioOption text="Yes" />
                                    <RadioOption text="No" />
                                </RadioGroup>
                            </FormField>

                            <FormField title="How many events would you like to include?">
                                <NumberInput
                                    placeholderText="No. of events"
                                    minVal={0}
                                    changeHandler={handleNumEventsChange}
                                />
                            </FormField>
                        </FormSection>
                    </FormPage>

                    {[...Array(numEvents)].map((_val, i) => {
                        return [
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"General Information - Event " + (i + 1)}>
                                        <FormField title="Name of the event">
                                            <TextInput placeholderText="Name of the event" />
                                        </FormField>

                                        <FormField title="Duration (days)">
                                            <NumberInput placeholderText="Number of days" minVal={1} />
                                        </FormField>

                                        <FormField title="Participants">
                                            <NumberInput placeholderText="Participants" minVal={1} />
                                        </FormField>

                                        <FormField title="Number of employees for the event">
                                            <NumberInput placeholderText="Number of employees" minVal={1} />
                                        </FormField>

                                        <FormField title="Include in the offset calculation?">
                                            <RadioGroup name={"include-event-in-offset-calc-" + i}>
                                                <RadioOption text="Yes" />
                                                <RadioOption text="No" />
                                            </RadioGroup>
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Mobility (Employees) - Event " + (i + 1)}>
                                        <FormField title="Number of people arriving by car">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Average distance travelled (car)">
                                            <NumberInput placeholderText="Distance in km" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of people arriving by public transport">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Average distance travelled (public transport)">
                                            <NumberInput placeholderText="Distance in km" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of short-haul flights (up to 3 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of medium-haul flights (3-6 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of long-haul flights (more than 6 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Percentage business class flights">
                                            <PercentageInput placeholderText="Percent" />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Mobility (Guests) - Event " + (i + 1)}>
                                        <FormField title="Number of people arriving by car">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Average distance travelled (car)">
                                            <NumberInput placeholderText="Distance in km" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of people arriving by public transport">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Average distance travelled (public transport)">
                                            <NumberInput placeholderText="Distance in km" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of short-haul flights (up to 3 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of medium-haul flights (3-6 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of long-haul flights (more than 6 hours)">
                                            <NumberInput placeholderText="Number of flights" minVal={0} />
                                        </FormField>

                                        <FormField title="Percentage business class flights">
                                            <PercentageInput placeholderText="Percent" />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Accommodation - Event " + (i + 1)}>
                                        <FormField title="Number of people staying overnight in a 2-3 star hotel">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of overnight stays in a 2-3 star hotel">
                                            <NumberInput placeholderText="Number of nights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of people staying overnight in a 4 star hotel">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of overnight stays in a 4 star hotel">
                                            <NumberInput placeholderText="Number of nights" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of people staying overnight in a 5 star hotel">
                                            <NumberInput placeholderText="Number of people" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of overnight stays in a 5 star hotel">
                                            <NumberInput placeholderText="Number of nights" minVal={0} />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Catering - Event " + (i + 1)}>
                                        <FormField title="Amount of meals (not vegetarian)">
                                            <NumberInput placeholderText="Number of meals" minVal={0} />
                                        </FormField>

                                        <FormField title="Amount of vegetarian meals">
                                            <NumberInput placeholderText="Number of meals" minVal={0} />
                                        </FormField>

                                        <FormField title="Amount of snacks">
                                            <NumberInput placeholderText="Number of snacks" minVal={0} />
                                        </FormField>

                                        <FormField title="Water / soft drinks">
                                            <NumberInput placeholderText="Litres" minVal={0} />
                                        </FormField>

                                        <FormField title="Coffee">
                                            <NumberInput placeholderText="Cups" minVal={0} />
                                        </FormField>

                                        <FormField title="Tea">
                                            <NumberInput placeholderText="Cups" minVal={0} />
                                        </FormField>

                                        <FormField title="Wine">
                                            <NumberInput placeholderText="Cups" minVal={0} />
                                        </FormField>

                                        <FormField title="Beer">
                                            <NumberInput placeholderText="Cups" minVal={0} />
                                        </FormField>

                                        <FormField title="Spirits">
                                            <NumberInput placeholderText="Cups" minVal={0} />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Materials - Event " + (i + 1)}>
                                        <FormField title="Printed">
                                            <NumberInput placeholderText="kg or ream" minVal={0} />
                                        </FormField>

                                        <FormField title="Plastics">
                                            <NumberInput placeholderText="kg" minVal={0} />
                                        </FormField>

                                        <FormField title="Recyclable material">
                                            <NumberInput placeholderText="kg" minVal={0} />
                                        </FormField>

                                        <FormField title="Wood, carton, paper and plant-based materials">
                                            <NumberInput placeholderText="kg" minVal={0} />
                                        </FormField>

                                        <FormField title="Area of stand">
                                            <NumberInput
                                                placeholderText="m² if applicable" minVal={0}
                                            />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            )
                        ];
                    })}

                    {[...Array(numAssets)].map((_val, i) => {
                        return [
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Full Address - Asset " + (i + 1)}>
                                        <FormField 
                                            title="Is it the head quarters / registered office / main office?"
                                        >
                                            <RadioGroup name={"asset-is-main-" + i}>
                                                <RadioOption text="Yes" />
                                                <RadioOption text="No" />
                                            </RadioGroup>
                                        </FormField>

                                        <FormField
                                            title="Name of asset / property group"
                                            description="Note: the name of the asset entered here will appear
                                                above the asset's address on any certificate produced for this
                                                asset. Certificate shows a maximum of 28 characters."
                                        >
                                            <TextInput placeholderText="Name of asset / property group" />
                                        </FormField>

                                        <FormField title="Address line 1">
                                            <TextInput placeholderText="Line 1" />
                                        </FormField>

                                        <FormField
                                            title="Address line 2"
                                            description="Note: address lines 1 and 2 will be combined as a single
                                                line on the certificate and a maximum of 28 characters will be 
                                                shown."
                                        >
                                            <TextInput placeholderText="Line 2" />
                                        </FormField>

                                        <FormField title="Address line 3">
                                            <TextInput placeholderText="Line 3" />
                                        </FormField>

                                        <FormField
                                            title="Address line 4"
                                            description="Note: address lines 3 and 4 will be combined as a single
                                                line on the certificate and a maximum of 28 characters will be 
                                                shown."
                                        >
                                            <TextInput placeholderText="Line 4" />
                                        </FormField>

                                        <FormField title="Town/City">
                                            <TextInput placeholderText="Town/City" />
                                        </FormField>

                                        <FormField title="County/Region/State">
                                            <TextInput placeholderText="County/Region/State" />
                                        </FormField>

                                        <FormField title="Post code">
                                            <TextInput placeholderText="Post code" />
                                        </FormField>

                                        <FormField title="Building floor area (m²)">
                                            <NumberInput placeholderText="Building floor area (m²)" minVal={0} />
                                        </FormField>

                                        <FormField title="Include in the offset calculation?">
                                            <RadioGroup name={"include-asset-in-offset-calc-" + i}>
                                                <RadioOption text="Yes" />
                                                <RadioOption text="No" />
                                            </RadioGroup>
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Basic Building Details - Asset " + (i + 1)}>
                                        <FormField title="Name of the person or organisation that owns the asset">
                                            <TextInput placeholderText="Name" />
                                        </FormField>

                                        <FormField title="Name of the person or organisation that manages the
                                            tenancy of the asset"
                                        >
                                            <TextInput placeholderText="Name" />
                                        </FormField>

                                        <FormField title="Name of the person or organisation that occupies/leases
                                            the asset"
                                        >
                                            <TextInput placeholderText="Name" />
                                        </FormField>

                                        <FormField title="Asset description (max 500 characters)">
                                            <LongTextInput placeholderText="Description" charLimit={500} />
                                        </FormField>

                                        <FormField title="Add other stakeholders (sustainability consultant, MEP,
                                            partners...)"
                                        >
                                            <TextInput placeholderText="Name" />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"General Information - Asset " + (i + 1)}>
                                        <FormField title="Building type (e.g. community group, office, theatre,
                                            museum, gallery...)"
                                        >
                                            <TextInput placeholderText="Building type" />
                                        </FormField>

                                        <FormField title="Building subtype (e.g. head quarters, main office,
                                            warehouse, supporting building...)">
                                            <TextInput placeholderText="Subtype" />
                                        </FormField>

                                        <FormField title="Construction year">
                                            <YearInput
                                                placeholderText="Year"
                                                minVal={1000}
                                                maxVal={new Date().getFullYear()}
                                            />
                                        </FormField>

                                        <FormField title="Year of last major refurbishment">
                                            <YearInput
                                                placeholderText="Year"
                                                minVal={1000}
                                                maxVal={new Date().getFullYear()}
                                            />
                                        </FormField>

                                        <FormField title="Is there a café/restaurant based in the building?">
                                            <RadioGroup name={"asset-has-cafe-or-restaurant-" + i}>
                                                <RadioOption text="Yes" />
                                                <RadioOption text="No" />
                                            </RadioGroup>
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Occupancy - Asset " + (i + 1)}>
                                        <FormField title="Number of occupants">
                                            <NumberInput placeholderText="Number" minVal={0} />
                                        </FormField>

                                        <FormField title="Annual operational days">
                                            <NumberInput placeholderText="Days" minVal={0} />
                                        </FormField>

                                        <FormField title="Daily operational hours">
                                            <NumberInput placeholderText="Hours" minVal={0} />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Dimensions - Asset " + (i + 1)}>
                                        <FormField title="Gross internal area (GIA scope only - m²)">
                                            <NumberInput placeholderText="Area" minVal={0} />
                                        </FormField>

                                        <FormField title="Measurement standard">
                                            <TextInput
                                                placeholderText="Code of measuring practice (RICS)"
                                            />
                                        </FormField>

                                        <FormField title="Non-lettable area (m²)">
                                            <NumberInput placeholderText="Area" minVal={0} />
                                        </FormField>

                                        <FormField title="Gross lettable area (m²)">
                                            <NumberInput placeholderText="Area" minVal={0} />
                                        </FormField>

                                        <FormField title="Planning restrictions (listed?)">
                                            <TextInput placeholderText="Planning restrictions" />
                                        </FormField>

                                        <FormField title="Width (external façade - m)">
                                            <NumberInput placeholderText="Width" minVal={0} />
                                        </FormField>

                                        <FormField title="Length (external façade - m)">
                                            <NumberInput placeholderText="Length" minVal={0} />
                                        </FormField>

                                        <FormField title="Height (floor-to-floor height - m)">
                                            <NumberInput placeholderText="Height" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of floors above ground">
                                            <NumberInput placeholderText="Floors" minVal={0} />
                                        </FormField>

                                        <FormField title="Number of floors below ground">
                                            <NumberInput placeholderText="Floors" minVal={0} />
                                        </FormField>

                                        <FormField title="Area covered by hard landscaping (m²)">
                                            <NumberInput placeholderText="Area" minVal={0} />
                                        </FormField>

                                        <FormField title="Area covered by soft landscaping (m²)">
                                            <NumberInput placeholderText="Area" minVal={0} />
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            ),
                            (
                                <FormPage title="General Information">
                                    <FormSection title={"Energy - Asset " + (i + 1)}>
                                        <FormField title="Power consumption">
                                            <NumberInput placeholderText="kWh" minVal={0} />
                                        </FormField>

                                        <FormField title="Green energy?">
                                            <RadioGroup name={"asset-green-energy-" + i}>
                                                <RadioOption text="Yes" />
                                                <RadioOption text="No" />
                                            </RadioGroup>
                                        </FormField>
                                    </FormSection>
                                </FormPage>
                            )
                        ];
                    })}
                </FormStep>

                <FormStep title="Energy">
                    <FormPage title="Energy">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Energy Use">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>
                            </FormSection>

                            <FormSection title="Consumption">
                                <Columns>
                                    <FormField
                                        title="Mains electricity use"
                                        scope={2}
                                        aggIndex={0}
                                        copyScope={3}
                                        copyAggIndex={4}
                                    >
                                        <NumberInput placeholderText="kWh" minVal={0} />
                                    </FormField>

                                    <FormField title="Mains gas use" scope={1} aggIndex={1}>
                                        <NumberInput placeholderText="kWh" minVal={0} />
                                    </FormField>

                                    <FormField title="Diesel use" scope={1} aggIndex={2}>
                                        <NumberInput placeholderText="Litres" minVal={0} />
                                    </FormField>

                                    <FormField title="Bottled gas use">
                                        <NumberInput placeholderText="Litres" minVal={0} />
                                    </FormField>

                                    <FormField title="Oil use" scope={1} aggIndex={3}>
                                        <NumberInput placeholderText="Litres" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Onsite renewable energy"
                                        infoText="If you have any renewable energy sources on your building, 
                                            such as photovoltaic (PV) panels, provide the kWh of energy 
                                            produced. This can be found via your building management system 
                                            or a net metering bill. If you are also feeding back into the 
                                            grid, please let us know the total kWh of energy in the Notes box 
                                            on the next page. We will then calculate offline the amount of 
                                            carbon dioxide equivalent emissions that you have saved by 
                                            feeding energy back into the grid."
                                        scope={1}
                                        aggIndex={7}
                                    >
                                        <NumberInput placeholderText="kWh" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Other (e.g. district heating)"
                                        scope={0}
                                        aggIndex={6}
                                        copyScope={3}
                                        copyAggIndex={5}
                                    >
                                        <NumberInput placeholderText="kWh or %" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Provide conversion factor for other"
                                        cfIndex={6}
                                    >
                                        <NumberInput placeholderText="Conversion factor" />
                                    </FormField>
                                </Columns>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Energy">
                        <FormSection title="Energy Supplier">
                            <FormField title="Electricity supplier">
                                <TextInput placeholderText="Supplier" />
                            </FormField>

                            <FormField
                                title="Green electricity tariff"
                                infoText="All suppliers in the UK get at least some of their electricity from 
                                    renewable sources and most offer what’s known as ‘green’ tariff 
                                    electricity. If you are on such a tariff, it means one of two things: 
                                    1) the supplier promises to match your usage with generation from 
                                    renewable sources, and invest in additional generation or contribute to 
                                    environmental schemes that lower carbon dioxide emissions (a ‘green’ 
                                    tariff with additionality) 2) the supplier promises to match your usage 
                                    with generation from renewable sources, but doesn’t offer any additional 
                                    environmental benefits (a ‘green’ tariff without additionality)"
                            >
                                <RadioGroup name="green-electricity-tariff">
                                    <RadioOption text="Yes" />
                                    <RadioOption text="No" />
                                    <RadioOption text="Don't know" />
                                </RadioGroup>
                            </FormField>


                        </FormSection>

                        <FormSection title="Energy Use Notes">
                            <FormField
                                title="Green electricity tariff details"
                                description="If you use one, please give details of your green electricity 
                                    tariff supplier and the tariff name. A green electricity tariff is a 
                                    tariff that the energy supplier claims has environmental benefits."
                                descriptionBefore={true}
                            >
                                <LongTextInput placeholderText="Details" />
                            </FormField>

                            <FormField
                                title="Energy notes"
                                description="Please add any notes about your data sources and assumptions 
                                    for energy estimates."
                                descriptionBefore={true}
                            >
                                <LongTextInput placeholderText="Details" />
                            </FormField>
                        </FormSection>
                    </FormPage>

                    <FormPage title="Energy">
                        <FormSection title="Custom Emissions">
                            <FormField
                                title="Descriptions"
                                description="A sample including the first and last bill during the period"
                                descriptionBefore={true}
                            >
                                <LongTextInput placeholderText="Description - emissions (kg CO₂e)" />
                            </FormField>
                        </FormSection>
                    </FormPage>

                    <FormPage title="Energy">
                        <Repeater addButtonText="Add another file" removeButtonText="Remove file">
                            <FormSection title="Attach File">
                                <FormField 
                                    title="Please attach energy bills (a sample including the first and the last 
                                        bill during the period)"
                                >
                                    <FileInput types={["image/png", "image/jpeg", ".pdf"]} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>
                </FormStep>

                <FormStep title="Water">
                    <FormPage title="Water">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Water Consumption">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>
                            </FormSection>

                            <FormSection title="Consumption">
                                <FormField title="Water use" scope={3} aggIndex={8}>
                                    <NumberInput placeholderText="m³" minVal={0} />
                                </FormField>

                                <FormField 
                                    title="Wastewater"
                                    description="Sewerage or wastewater in m³ can be found on you water or 
                                        sewerage bills. It is in litres, divided by 1000. If you can't find 
                                        sewerage data, then assume it's the same as your water use."
                                    descriptionBefore={true}
                                    scope={3}
                                    aggIndex={9}
                                >
                                    <NumberInput placeholderText="m³" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Water notes"
                                    description="Please add notes related to water data sources and 
                                        assumptions."
                                    descriptionBefore={true}
                                >
                                    <LongTextInput placeholderText="Notes" />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Water">
                        <FormSection title="Custom Emissions">
                            <FormField title="Descriptions">
                                <LongTextInput placeholderText="Description - emissions (kg CO₂e)" />
                            </FormField>
                        </FormSection>
                    </FormPage>

                    <FormPage title="Water">
                        <Repeater addButtonText="Add another file" removeButtonText="Remove file">
                            <FormSection title="Attach File">
                                <FormField 
                                    title="Please attach water bills (a sample including the first and the last 
                                        bill during the period)"
                                >
                                    <FileInput types={["image/png", "image/jpeg", ".pdf"]} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>
                </FormStep>

                <FormStep title="Waste">
                    <FormPage title="Waste">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Waste Consumption">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>
                            </FormSection>

                            <FormSection title="Consumption">
                                <Columns>
                                    <FormField title="Waste to landfill" scope={3} aggIndex={10}>
                                        <NumberInput placeholderText="Tonnes" minVal={0} />
                                    </FormField>

                                    <FormField title="Waste to energy" scope={3} aggIndex={11}>
                                        <NumberInput placeholderText="Tonnes" minVal={0} />
                                    </FormField>

                                    <FormField title="Waste to recycling" scope={3} aggIndex={12}>
                                        <NumberInput placeholderText="Tonnes" minVal={0} />
                                    </FormField>

                                    <FormField title="Waste to composting" scope={3} aggIndex={13}>
                                        <NumberInput placeholderText="Tonnes" minVal={0} />
                                    </FormField>
                                </Columns>

                                <FormField
                                    title="Waste notes"
                                    description="Please add notes related to waste data sources and 
                                        assumptions"
                                    descriptionBefore={true}
                                >
                                    <LongTextInput placeholderText="Notes" />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Waste">
                        <FormSection title="Custom Emissions">
                            <FormField title="Descriptions">
                                <LongTextInput placeholderText="Description - emissions (kg CO₂e)" />
                            </FormField>
                        </FormSection>
                    </FormPage>

                    
                    <FormPage title="Waste">
                        <Repeater addButtonText="Add another file" removeButtonText="Remove file">
                            <FormSection title="Attach File">
                                <FormField 
                                    title="Please attach waste bills/tickets/reports (a sample including the 
                                        first and the last bill during the period)"
                                >
                                    <FileInput types={["image/png", "image/jpeg", ".pdf"]} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>
                </FormStep>

                <FormStep title="Business & Staff Travel">
                    <FormPage title="Business & Staff Travel">
                        <h4>How do you define domestic, short-haul, long-haul and international flights?</h4>

                        <p>
                            Broadly speaking, the definition of domestic flights is those within the UK, 
                            short-haul are those within Europe, long-haul are outside of Europe and 
                            international flights are those between non-UK destinations.
                        </p>

                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Business Travel (Scope 3)">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <FormField title="Distance unit" isMultiplierField={true}>
                                    <RadioGroup name="business-travel-distance-unit">
                                        <RadioOption text="miles" val={milesToKm}/>
                                        <RadioOption text="km" val={1}/>
                                    </RadioGroup>
                                </FormField>

                                <Columns>
                                    <FormField title="Car (small) | petrol" scope={3} aggIndex={14}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | petrol" scope={3} aggIndex={15}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | petrol" scope={3} aggIndex={16}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | petrol" scope={3} aggIndex={17}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | diesel" scope={3} aggIndex={18}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | diesel" scope={3} aggIndex={19}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | diesel" scope={3} aggIndex={20}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | diesel" scope={3} aggIndex={21}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | hybrid" scope={3} aggIndex={22}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | hybrid" scope={3} aggIndex={23}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | hybrid" scope={3} aggIndex={24}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | hybrid" scope={3} aggIndex={25}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | plug-in hybrid" scope={3} aggIndex={26}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | plug-in hybrid" scope={3} aggIndex={27}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | plug-in hybrid" scope={3} aggIndex={28}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | plug-in hybrid" scope={3} aggIndex={29}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (small)" scope={3} aggIndex={30}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (medium)" scope={3} aggIndex={31}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (large)" scope={3} aggIndex={32}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (average)" scope={3} aggIndex={33}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Taxi (regular taxi)" scope={3} aggIndex={34}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Taxi (black cab)" scope={3} aggIndex={35}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (local bus)" scope={3} aggIndex={36}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (local London bus)" scope={3} aggIndex={37}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (average local bus)" scope={3} aggIndex={38}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (coach)" scope={3} aggIndex={39}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (national rail)" scope={3} aggIndex={40}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (international rail)" scope={3} aggIndex={41}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (light rail and tram)" scope={3} aggIndex={42}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (underground)" scope={3} aggIndex={43}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>
                                </Columns>

                                <FormField
                                    title="Flight domestic (to/from UK) - Class (average passenger)"
                                    scope={3}
                                    aggIndex={44}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - economy"
                                    scope={3}
                                    aggIndex={45}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - business class"
                                    scope={3}
                                    aggIndex={46}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - average"
                                    scope={3}
                                    aggIndex={47}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Long haul (to/from UK) Class - economy"
                                    scope={3}
                                    aggIndex={48}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Long haul (to/from UK) Class - business class"
                                    scope={3}
                                    aggIndex={49}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Long haul (to/from UK) Class - average"
                                    scope={3}
                                    aggIndex={50}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - economy"
                                    scope={3}
                                    aggIndex={51}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - business class"
                                    scope={3}
                                    aggIndex={52}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - average"
                                    scope={3}
                                    aggIndex={53}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Business & Staff Travel">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Freighting Goods (Scope 3)">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <FormField title="Distance unit" isMultiplierField={true}>
                                    <RadioGroup name="freighting-goods-distance-unit">
                                        <RadioOption text="miles" val={milesToKm}/>
                                        <RadioOption text="km" val={1}/>
                                    </RadioGroup>
                                </FormField>

                                <Columns>
                                    <FormField
                                        title="Average van (up to 3.5 tonnes) | petrol"
                                        scope={3}
                                        aggIndex={54}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Average van (up to 3.5 tonnes) | diesel"
                                        scope={3}
                                        aggIndex={55}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="HGVs | diesel" scope={3} aggIndex={56}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Refrigerated HGVs | diesel" scope={3} aggIndex={57}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (domestic to/from UK)"
                                        scope={3}
                                        aggIndex={58}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (short-haul to/from UK)"
                                        scope={3}
                                        aggIndex={59}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (long-haul to/from UK)"
                                        scope={3}
                                        aggIndex={60}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (international to/from UK)"
                                        scope={3}
                                        aggIndex={61}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (freight train)" scope={3} aggIndex={62}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (bulk carrier)" scope={3} aggIndex={63}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (general cargo)" scope={3} aggIndex={64}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (container ship)" scope={3} aggIndex={65}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (vehicle transport)" scope={3} aggIndex={66}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Cargo ship (refrigerated cargo)"
                                        scope={3}
                                        aggIndex={67}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>
                                </Columns>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Business & Staff Travel">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Staff Commute (Scope 3)">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <FormField title="Distance unit" isMultiplierField={true}>
                                    <RadioGroup name="staff-commute-distance-unit">
                                        <RadioOption text="miles" val={milesToKm}/>
                                        <RadioOption text="km" val={1}/>
                                    </RadioGroup>
                                </FormField>

                                <Columns>
                                    <FormField title="Car (small) | petrol" scope={3} aggIndex={68}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | petrol" scope={3} aggIndex={69}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | petrol" scope={3} aggIndex={70}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | petrol" scope={3} aggIndex={71}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | diesel" scope={3} aggIndex={72}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | diesel" scope={3} aggIndex={73}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | diesel" scope={3} aggIndex={74}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | diesel" scope={3} aggIndex={75}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | hybrid" scope={3} aggIndex={76}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | hybrid" scope={3} aggIndex={77}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | hybrid" scope={3} aggIndex={78}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | hybrid" scope={3} aggIndex={79}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | plug-in hybrid" scope={3} aggIndex={80}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | plug-in hybrid" scope={3} aggIndex={81}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | plug-in hybrid" scope={3} aggIndex={82}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | plug-in hybrid" scope={3} aggIndex={83}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (small)" scope={3} aggIndex={84}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (medium)" scope={3} aggIndex={85}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (large)" scope={3} aggIndex={86}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Motorbike (average)" scope={3} aggIndex={87}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Taxi (regular taxi)" scope={3} aggIndex={88}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Taxi (black cab)" scope={3} aggIndex={89}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (local bus)" scope={3} aggIndex={90}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (local London bus)" scope={3} aggIndex={91}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (average local bus)" scope={3} aggIndex={92}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Bus (coach)" scope={3} aggIndex={93}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (national rail)" scope={3} aggIndex={94}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (international rail)" scope={3} aggIndex={95}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (light rail and tram)" scope={3} aggIndex={96}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (underground)" scope={3} aggIndex={97}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>
                                </Columns>

                                <FormField
                                    title="Flight domestic (to/from UK) - Class (average passenger)"
                                    scope={3}
                                    aggIndex={98}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - economy"
                                    scope={3}
                                    aggIndex={99}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - business class"
                                    scope={3}
                                    aggIndex={100}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Short haul (to/from UK) Class - average"
                                    scope={3}
                                    aggIndex={101}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Long haul (to/from UK) Class - economy"
                                    scope={3}
                                    aggIndex={102}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                   title="Long haul (to/from UK) Class - business class"
                                   scope={3}
                                   aggIndex={103}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="Long haul (to/from UK) Class - average"
                                    scope={3}
                                    aggIndex={104}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - economy"
                                    scope={3}
                                    aggIndex={105}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - business class"
                                    scope={3}
                                    aggIndex={106}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>

                                <FormField
                                    title="International (to/from non-UK) Class - average"
                                    scope={3}
                                    aggIndex={107}
                                >
                                    <NumberInput placeholderText="Distance" minVal={0} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Business & Staff Travel">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Private Vehicle for Business Use (Scope 2)">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <FormField title="Distance unit" isMultiplierField={true}>
                                    <RadioGroup name="private-vehicle-distance-unit">
                                        <RadioOption text="miles" val={milesToKm}/>
                                        <RadioOption text="km" val={1}/>
                                    </RadioGroup>
                                </FormField>

                                <Columns>
                                    <FormField title="Car (small) | petrol" scope={2} aggIndex={108}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | petrol" scope={2} aggIndex={109}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | petrol" scope={2} aggIndex={110}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | petrol" scope={2} aggIndex={111}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | diesel" scope={2} aggIndex={112}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | diesel" scope={2} aggIndex={113}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | diesel" scope={2} aggIndex={114}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | diesel" scope={2} aggIndex={115}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | hybrid" scope={2} aggIndex={116}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | hybrid" scope={2} aggIndex={117}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | hybrid" scope={2} aggIndex={118}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | hybrid" scope={2} aggIndex={119}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | plug-in hybrid" scope={2} aggIndex={120}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | plug-in hybrid" scope={2} aggIndex={121}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | plug-in hybrid" scope={2} aggIndex={122}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Car (average) | plug-in hybrid"
                                        scope={2}
                                        aggIndex={123}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>
                                </Columns>
                            </FormSection>
                        </Repeater>
                    </FormPage>

                    <FormPage title="Business & Staff Travel">
                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Company Fleet and Leased Vehicles (Scope 1)">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <FormField title="Distance unit" isMultiplierField={true}>
                                    <RadioGroup name="company-fleet-distance-unit">
                                        <RadioOption text="miles" val={milesToKm}/>
                                        <RadioOption text="km" val={1}/>
                                    </RadioGroup>
                                </FormField>

                                <Columns>
                                    <FormField
                                        title="Average van (up to 3.5 tonnes) | petrol"
                                        scope={1}
                                        aggIndex={124}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Average van (up to 3.5 tonnes) | diesel"
                                        scope={1}
                                        aggIndex={125}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="HGVs | diesel" scope={1} aggIndex={126}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Refrigerated HGVs | diesel" scope={1} aggIndex={127}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (domestic to/from UK)"
                                        scope={1}
                                        aggIndex={128}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (short-haul to/from UK)"
                                        scope={1}
                                        aggIndex={129}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (long-haul to/from UK)"
                                        scope={1}
                                        aggIndex={130}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Freight flights (international to/from UK)"
                                        scope={1}
                                        aggIndex={131}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Rail (freight train)" scope={1} aggIndex={132}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (bulk carrier)" scope={1} aggIndex={133}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (general cargo)" scope={1} aggIndex={134}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Cargo ship (container ship)" scope={1} aggIndex={135}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Cargo ship (vehicle transport)"
                                        scope={1}
                                        aggIndex={136}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Cargo ship (refrigerated cargo)"
                                        scope={1}
                                        aggIndex={137}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | petrol" scope={1} aggIndex={138}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | petrol" scope={1} aggIndex={139}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | petrol" scope={1} aggIndex={140}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | petrol" scope={1} aggIndex={141}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | diesel" scope={1} aggIndex={142}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | diesel" scope={1} aggIndex={143}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | diesel" scope={1} aggIndex={144}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | diesel" scope={1} aggIndex={145}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | hybrid" scope={1} aggIndex={146}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | hybrid" scope={1} aggIndex={147}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | hybrid" scope={1} aggIndex={148}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (average) | hybrid" scope={1} aggIndex={149}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (small) | plug-in hybrid" scope={1} aggIndex={150}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (medium) | plug-in hybrid" scope={1} aggIndex={151}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField title="Car (large) | plug-in hybrid" scope={1} aggIndex={152}>
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>

                                    <FormField
                                        title="Car (average) | plug-in hybrid"
                                        scope={1}
                                        aggIndex={153}
                                    >
                                        <NumberInput placeholderText="Distance" minVal={0} />
                                    </FormField>
                                </Columns>
                            </FormSection>
                        </Repeater>
                    </FormPage>
                </FormStep>

                <FormStep title="Refrigerants">
                    <FormPage title="Refrigerants">
                        <p>
                            Top-ups made to air conditioning units - Many refrigeration, fire protection and 
                            air conditioning equipment contains a type of F gas (Fluorinated greenhouse gas), 
                            which has a large carbon footprint. Please include the type and quantity of 
                            top-up that has been done during the reporting year. You can find this 
                            information in the service sheets provided by your air conditioning or 
                            refrigeration contractor.
                        </p>

                        <Repeater addButtonText="Add another date range" removeButtonText="Remove date range">
                            <FormSection title="Refrigerant Consumption">
                                <FormField title="Period - dates">
                                    <DateRangeInput dateRanges={dateRanges} />
                                </FormField>

                                <Columns>
                                    <FormField title="R404A" scope={1} aggIndex={154}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="R407A" scope={1} aggIndex={155}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="R407C" scope={1} aggIndex={156}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="R408A" scope={1} aggIndex={157}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="R410A" scope={1} aggIndex={158}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="R134A" scope={1} aggIndex={159}>
                                        <NumberInput placeholderText="kg" minVal={0} />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>
                                </Columns>
                            </FormSection>

                            <Repeater
                                addButtonText="Add custom refrigerant to this date range"
                                removeButtonText="Remove refrigerant"
                                initiallyEmpty={true}
                            >
                                <FormSection title="Custom Refrigerant">
                                    <FormField title="Emission type">
                                        <TextInput placeholderText="Emission type" />
                                    </FormField>

                                    <FormField title="Notes">
                                        <TextInput placeholderText="Notes" />
                                    </FormField>

                                    <FormField title="Emission factor">
                                        <TextInput placeholderText="Emission factor" />
                                    </FormField>
                                </FormSection>
                            </Repeater>
                        </Repeater>

                        <Repeater addButtonText="Add another file" removeButtonText="Remove file">
                            <FormSection title="Attach File">  
                                <FormField 
                                    title="Please attach refrigerant charge receipts/spreadsheets/reports 
                                        (a sample including the first and last bills during the period)"
                                >
                                    <FileInput types={['.pdf', '.xlsx', '.docx']} />
                                </FormField>
                            </FormSection>
                        </Repeater>
                    </FormPage>
                </FormStep>

                <FormStep title="Results">
                    <FormPage title="Your Results" isSubmissionPage={true}>
                        <Results inputs={inputs} co2es={co2es} dateRanges={dateRanges} />
                    </FormPage>
                </FormStep>

                <FormStep title="Thank You">
                    <FormPage title="Thank You" displayNav={false}>
                        <p>Thank you for your submission! We'll be in touch shortly.</p>
                    </FormPage>
                </FormStep>
            </Form>
        </>
    );
};

export default CarbonCalcForm;
