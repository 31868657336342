import React from 'react';

const StepIndicator = ({ step, totalSteps, clickHandler }) => {
    return (
        <div className="step-indicator">
            <div className="steps">
                {[...Array(totalSteps)].map((_val, i) => {
                    return (
                        <div
                            className={"step" + (i === step ? " active" : "")}
                            //onClick={() => {clickHandler(i)}} // Uncomment to help with testing
                        >
                        </div>
                    );
                })}
            </div>

            <hr className="connector"></hr>
        </div>
    );
};

export default StepIndicator;
