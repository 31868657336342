import React, { Children, cloneElement } from 'react';

const Columns = ({
    children,
    cols = 2,
    formComponentId,
    updateSummaryCallback,
    updateEmailDataCallback,
    updateCfCallback
}) => {
    const renderChildren = () => {
        return Children.map(
            children,
            (child, i) => cloneElement(
                child,
                {
                    updateSummaryCallback: updateSummaryCallback,
                    updateEmailDataCallback: updateEmailDataCallback,
                    formComponentId: formComponentId.toString() + '-' + i.toString(),
                    updateCfCallback: updateCfCallback
                }
            )
        );
    };

    return (
        <div className={2 === cols ? 'two-cols' : ''}>
            {renderChildren()}
        </div>
    );
};

export default Columns;
