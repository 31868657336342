import React from 'react';

const RadioOption = ({ text, val, name, num, inputChangeCallback }) => {
    const inputId = 'radio-' + name + '-' + num;

    const handleChange = ({ target }) => {
        if (target.checked) {
            inputChangeCallback(val ?? text, false, '', text);
        }
    };

    return (
        <div className="radio-option">
            <input type="radio" name={name} id={inputId} onChange={handleChange} />
            <label for={inputId}>{text}</label>
        </div>
    );
};

export default RadioOption;
