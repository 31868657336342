import React, { useState } from 'react';
import Repeat from './Repeat';

const Repeater = ({
    children,
    addButtonText,
    removeButtonText,
    initiallyEmpty = false,
    formComponentId,
    updateCo2esCallback,
    deleteCo2esCallback,
    updateEmailDataCallback,
    deleteEmailDataCallback,
    updateCfsCallback
}) => {
    const [repeats, setRepeats] = useState(initiallyEmpty ? [] : [0]);
    const [_repeatKey, setRepeatKey] = useState(initiallyEmpty ? 0 : 1);

    const handleClickAddButton = () => {
        setRepeatKey((prevRepeatKey) => {
            setRepeats(prevRepeats => prevRepeats.concat([prevRepeatKey]));

            return prevRepeatKey + 1;
        });
    };

    const removeRepeat = (repeatNum) => {
        setRepeats((prevRepeats) => {
            const updatedRepeats = [...prevRepeats];
            updatedRepeats.splice(updatedRepeats.indexOf(repeatNum), 1);

            return updatedRepeats;
        });
    };

    const [_repeatSummaries, setRepeatSummaries] = useState({});

    const updateSummary = (summary, dateRangeIndex, multiplier, repeatNum) => {
        setRepeatSummaries((prevRepeatSummaries) => {
            if (!prevRepeatSummaries) {
                prevRepeatSummaries = {};
            }

            prevRepeatSummaries[repeatNum] = {summary: summary, dateRangeIndex: dateRangeIndex};
            const compiledSummary = {};

            Object.values(prevRepeatSummaries).forEach((repeatSummary) => {
                Object.entries(repeatSummary.summary).forEach(([aggIndex, summaryEntry]) => {
                    if (compiledSummary[aggIndex]) {
                        Object.assign(
                            compiledSummary[aggIndex].vals,
                            {[repeatSummary.dateRangeIndex]: multiplier * summaryEntry.val}
                        );
                    } else {
                        compiledSummary[aggIndex] = {
                            scope: summaryEntry.scope,
                            vals: {[repeatSummary.dateRangeIndex]: multiplier * summaryEntry.val}
                        }
                    }
                });
            });

            updateCo2esCallback(compiledSummary);

            return prevRepeatSummaries;
        });
    };

    const deleteSummary = (dateRangeIndex, repeatNum) => {
        setRepeatSummaries((prevRepeatSummaries) => {
            if (prevRepeatSummaries[repeatNum]) {
                const aggIndices = Object.keys(prevRepeatSummaries[repeatNum].summary);
                
                deleteCo2esCallback(dateRangeIndex, aggIndices);
                delete prevRepeatSummaries[repeatNum];

                return prevRepeatSummaries;
            }
        });
    };

    const [_cfs, setCfs] = useState({});

    const updateCfs = (cfsToUpdate, dateRangeIndex) => {
        setCfs((prevCfs) => {
            if (prevCfs[dateRangeIndex]) {
                prevCfs[dateRangeIndex] = Object.assign(prevCfs[dateRangeIndex], cfsToUpdate);
            } else {
                prevCfs[dateRangeIndex] = cfsToUpdate;
            }

            updateCfsCallback(prevCfs);

            return prevCfs;
        });
    }

    const renderChildren = () => {
        return repeats.map((key, i) => 
            <Repeat
                key={key.toString()}
                repeatNum={key}
                removeRepeat={removeRepeat}
                removeButtonText={removeButtonText}
                updateCo2esCallback={updateCo2esCallback}
                updateSummaryCallback={updateSummary}
                deleteSummaryCallback={deleteSummary}
                updateEmailDataCallback={updateEmailDataCallback}
                deleteEmailDataCallback={deleteEmailDataCallback}
                formComponentId={formComponentId.toString() + '-' + key.toString()}
                repeatIndex={i}
                repeaterInitiallyEmpty={initiallyEmpty}
                updateCfsCallback={updateCfs}
            >
                {children}
            </Repeat>
        );
    };

    return (
        <div className={'repeater' + (initiallyEmpty ? ' always-show-remove-button' : '')}>
            <div className="repeats">{renderChildren()}</div>
            
            <div className="repeat-button-container add-repeat-button-container">
                <button onClick={handleClickAddButton}>{addButtonText}</button>
            </div>
        </div>
    );
};

export default Repeater;
