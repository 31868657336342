import React, { useState, useEffect } from 'react';

const EmailEditor = () => {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');

    const getEmail = () => {
        jQuery.ajax({
            dataType: 'text',
            url: jdsAjax.ajaxUrl,
            data: {action: 'get_email'},
            success: (response) => {
                setLoading(false);
                setEmail(response);
            }
        });
    };

    useEffect(getEmail, []);

    const handleInputChange = ({ target }) => {
        setEmail(target.value);
    };

    const handleClickUpdate = () => {
        setLoading(true);

        jQuery.ajax({
            dataType: 'text',
            url: jdsAjax.ajaxUrl,
            data: {action: 'update_email', jds_email: email},
            success: (response) => {
                setLoading(false);
                setEmail(response);
            }
        });
    }

    return (
        <div className="card email-editor">
            <h2>Submission Email</h2>
            <p>Form submissions will be sent to this email address.</p>

            <div className="input-container">
                <label for="input-email">Email:</label>

                <input 
                    type="text"
                    id="input-email"
                    disabled={loading}
                    onChange={handleInputChange}
                    value={email}
                />
            </div>

            <button className="button-primary" onClick={handleClickUpdate}>Update</button>
        </div>
    );
};

export default EmailEditor;
