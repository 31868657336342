import React from 'react';
import NumberInput from './NumberInput';

const PercentageInput = ({ placeholderText, inputChangeCallback }) => {
    return (
        <NumberInput
            placeholderText={placeholderText}
            minVal={0}
            maxVal={100}
            inputChangeCallback={inputChangeCallback}
        />
    );
};

export default PercentageInput;
