import React from 'react';

const LongTextInput = ({ placeholderText, charLimit, inputChangeCallback }) => {
    const handleChange = ({ target }) => {
        inputChangeCallback(target.value);
    };

    return <textarea placeholder={placeholderText} maxlength={charLimit} onChange={handleChange}></textarea>;
};

export default LongTextInput;
