import React, { useState, Children, cloneElement } from 'react';

const FormField = ({
    children,
    title,
    description,
    descriptionBefore,
    infoText,
    scope,
    aggIndex,
    copyScope,
    copyAggIndex,
    cfIndex,
    updateCfCallback,
    formComponentId,
    updateSummaryCallback,
    updateDateRangeCallback,
    updateEmailDataCallback,
    isMultiplierField = false,
    updateMultiplierCallback
}) => {
    const [infoExpanded, setInfoExpanded] = useState(false);

    const handleOnMouseEnterExpand = () => {
        setInfoExpanded(true);
    };

    const handleOnMouseLeaveExpand = () => {
        setInfoExpanded(false);
    };

    const handleInputChange = (val, isDateRange = false, unit = '', text = '') => {
        if (isDateRange) {
            updateDateRangeCallback(val);

            return;
        }

        updateEmailDataCallback(
            title + (unit ? ' (' + unit + ')' : ''),
            formComponentId,
            isMultiplierField ? text : val.toString()
        );

        if (cfIndex) {
            updateCfCallback(cfIndex, val);

            return;
        }

        if (isMultiplierField) {
            updateMultiplierCallback(val);
        }

        if (scope && (aggIndex || 0 === aggIndex)) {
            updateSummaryCallback(aggIndex, scope, val);
        }

        if (copyScope && (copyAggIndex || 0 === copyAggIndex)) {
            updateSummaryCallback(copyAggIndex, copyScope, val);
        }
    };

    const renderDescription = () => {
        return  <p class="field-description">{description}</p>;
    };

    return (
        <div className="form-field">
            <p className="field-title">
                {title}

                {
                    infoText ? 
                        <span
                            className="expand"
                            onMouseEnter={handleOnMouseEnterExpand}
                            onMouseLeave={handleOnMouseLeaveExpand}
                        >
                            i
                            {infoExpanded ? <div className="additional-info"><p>{infoText}</p></div> : ''}
                        </span> :
                    ''
                }
            </p>
            
            {descriptionBefore && description ? renderDescription() : ''}
            {cloneElement(Children.toArray(children)[0], {inputChangeCallback: handleInputChange})}
            {!descriptionBefore && description ? renderDescription() : ''}
        </div>
    );
};

export default FormField;
