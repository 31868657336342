import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import PdfButton from './PdfButton';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Results = ({ inputs, co2es, dateRanges, pdfPageIds, generatePdfCallback }) => {
    const loading = 0 === Object.keys(dateRanges).length;
    const latestDateRangeIndex = dateRanges.length - 1;
    const latestDateRange = loading ? null : dateRanges[latestDateRangeIndex];
    const scopes = [1, 2, 3];
    const co2eByScopeThenDateRange = {};

    scopes.forEach((scope) => {
        const co2esForScope = {};

        dateRanges.forEach((_dateRange, i) => {
            co2esForScope[i] = 0;
        });

        co2eByScopeThenDateRange[scope] = co2esForScope;
    });

    const catNames = [
        'Energy',
        'Water',
        'Waste',
        'Transport',
        'Refrigerants',
    ];

    const aggIndexToCat = (aggIndex) => {
        if (aggIndex >= 0 && aggIndex <= 7) {
            return 0;
        }

        if (aggIndex >= 8 && aggIndex <= 9) {
            return 1;
        }

        if (aggIndex >= 10 && aggIndex <= 13) {
            return 2;
        }

        if (aggIndex >= 14 && aggIndex <= 153) {
            return 3;
        }

        if (aggIndex >= 154 && aggIndex <= 160) {
            return 4;
        }
    };

    const co2eByCatThenDateRange = [];

    catNames.forEach((_name, catIndex) => {
        const co2esForCat = {};

        dateRanges.forEach((_dateRange, i) => {
            co2esForCat[i] = 0;
        });

        co2eByCatThenDateRange[catIndex] = co2esForCat;
    });

    Object.entries(co2es).forEach(([aggIndex, co2eEntry]) => {
        Object.entries(co2eEntry.vals).forEach(([dateRangeIndex, val]) => {
            co2eByScopeThenDateRange[co2eEntry.scope][dateRangeIndex] += val;
            co2eByCatThenDateRange[aggIndexToCat(aggIndex)][dateRangeIndex] += val;
        });
    });

    const getInput = (aggIndex, dateRangeIndex) => {
        return getInputOrCo2e(inputs, aggIndex, dateRangeIndex);
    };

    const getCo2e = (aggIndex, dateRangeIndex) => {
        return getInputOrCo2e(co2es, aggIndex, dateRangeIndex);
    };

    const getInputOrCo2e = (obj, aggIndex, dateRangeIndex) => {
        if (obj[aggIndex]) {
            if (obj[aggIndex].vals[dateRangeIndex]) {
                return obj[aggIndex].vals[dateRangeIndex].toFixed(3);
            }
        }

        return '0.000';
    };

    const getInputSum = (startAggIndex, endAggIndex, dateRangeIndex) => {
        return getInputOrCo2eSum(inputs, startAggIndex, endAggIndex, dateRangeIndex);
    };

    const getCo2eSum = (startAggIndex, endAggIndex, dateRangeIndex) => {
        return getInputOrCo2eSum(co2es, startAggIndex, endAggIndex, dateRangeIndex);
    };

    const getInputOrCo2eSum = (obj, startAggIndex, endAggIndex, dateRangeIndex) => {
        let total = 0;

        for (let i = startAggIndex; i <= endAggIndex; i += 1) {
            if (obj[i]) {
                if (obj[i].vals[dateRangeIndex]) {
                    total += obj[i].vals[dateRangeIndex];
                }
            }
        }

        if (0 === total) {
            return '0.000';
        }

        return total.toFixed(3);
    };

    const chartColors = ['#3e7acf', '#dfa42e', '#adadad', '#aabd2f', '#25c26b', '#8d3cb5', '#b53c66'];
    const getChartColor = i => chartColors[i % chartColors.length];

    const emissionSummaryCo2es = [
        getCo2e(1, latestDateRangeIndex),
        getCo2eSum(124, 153, latestDateRangeIndex),
        (parseFloat(getCo2e(0, latestDateRangeIndex)) +
            parseFloat(getCo2e(4, latestDateRangeIndex))).toFixed(3),
        getCo2eSum(108, 123, latestDateRangeIndex),
        getCo2eSum(14, 53, latestDateRangeIndex),
        getCo2eSum(68, 107, latestDateRangeIndex),
        getCo2eSum(54, 67, latestDateRangeIndex),
        getCo2eSum(8, 9, latestDateRangeIndex),
        getCo2eSum(10, 13, latestDateRangeIndex),
        getCo2eSum(154, 160, latestDateRangeIndex)
    ];

    if (loading) {
        return <h3>Loading...</h3>;
    }

    return (
        <div id="jds-sq-carboncalc-form-results" className="results">
            <div className="latest-date-range" id={pdfPageIds[0]}>
                <h3>{latestDateRange.endYear} Calculation</h3>

                <div className="table-scopes">
                    <div className="headings">
                        <h4>kg CO<sub>2</sub>e</h4>
                        {scopes.map(scope => <p>{'Scope ' + scope}</p>)}
                    </div>

                    <div className="values">
                        <div></div>

                        {Object.values(co2eByScopeThenDateRange).map(
                            co2esForScope => <p>{co2esForScope[latestDateRangeIndex].toFixed(3)}</p>
                        )}
                    </div>
                </div>

                <Bar
                    data={{
                        labels: scopes.map(scope => 'Scope ' + scope),
                        datasets: [
                            {
                                label: 'kg CO₂e',
                                data: Object.values(co2eByScopeThenDateRange).map(
                                    co2esForScope => co2esForScope[latestDateRangeIndex]
                                ),
                                backgroundColor: getChartColor(0)
                            }
                        ]
                    }}
                    options={{plugins: {legend: {display: false}}}}
                />

                <div className="table-cats">
                    {co2eByCatThenDateRange.map((co2esForCat, catIndex) => 
                        <div>
                            <p>{catNames[catIndex]}</p>
                            <p className="value">{co2esForCat[latestDateRangeIndex].toFixed(3)}</p>
                        </div>
                    )}
                </div>

                <Bar
                    data={{
                        labels: catNames,
                        datasets: [
                            {
                                label: 'kg CO₂e',
                                data: co2eByCatThenDateRange.map(
                                    co2esForCat => co2esForCat[latestDateRangeIndex]
                                ),
                                backgroundColor: getChartColor(0)
                            }
                        ]
                    }}
                    options={{plugins: {legend: {display: false}}}}
                />

                <div className="renewable">
                    <h4>{latestDateRange.endYear} Renewable Energy Generation</h4>

                    <div>
                        <p className="value">
                            {
                                co2es[7] ? 
                                    co2es[7].vals[latestDateRangeIndex] ?
                                        -co2es[7].vals[latestDateRangeIndex].toFixed(3) :
                                        '0.000' :
                                    '0.000'
                            }
                        </p>

                        <p>kg CO<sub>2</sub>e saved</p>
                    </div>
                </div>
            </div>

            <div id={pdfPageIds[1]}>
                <h3>Annual Summary of kg CO<sub>2</sub>e</h3>

                <table>
                    <tr>
                        <td></td>
                        {Object.values(dateRanges).map(dateRange => <th>{dateRange.endYear}</th>)}
                    </tr>

                    {catNames.map((catName, catIndex) => 
                        <tr>
                            <th>{catName}</th>

                            {Object.values(co2eByCatThenDateRange[catIndex]).map(val =>
                                <td>{val.toFixed(3)}</td>
                            )}
                        </tr>
                    )}
                </table>

                <Bar
                    data={{
                        labels: catNames,
                        datasets: Object.entries(dateRanges).map(([dateRangeIndex, dateRange]) => {
                            return {
                                label: dateRange.endYear,
                                data: co2eByCatThenDateRange.map(co2eEntry => co2eEntry[dateRangeIndex]),
                                backgroundColor: getChartColor(dateRangeIndex)
                            };
                        })
                    }}
                    options={{plugins: {title: {display: true, text: 'Material Use (kg CO₂e)'}}}}
                />

                <h3>Carbon Emissions Scope Summary</h3>

                <table>
                    <tr>
                        <td></td>
                        {Object.values(dateRanges).map(dateRange => <th>{dateRange.endYear}</th>)}
                    </tr>
                    
                    {scopes.map(scope =>
                        <tr>
                            <th>{'Scope ' + scope}</th>
                            {Object.values(co2eByScopeThenDateRange[scope]).map(val => <td>{val.toFixed(3)}</td>)}
                        </tr>
                    )}

                    <tr className="row-total">
                        <th>Total</th>

                        {dateRanges.map((_dateRange, dateRangeIndex) => {
                            let total = 0;

                            Object.values(co2eByScopeThenDateRange).forEach((co2eEntry) => {
                                total += co2eEntry[dateRangeIndex];
                            });

                            return <td>{total.toFixed(3)}</td>;
                        })}
                    </tr>
                </table>
            </div>

            <div id={pdfPageIds[2]}>
                <Bar
                    data={{
                        labels: dateRanges.map(dateRange => dateRange.endYear),
                        datasets: Object.entries(co2eByScopeThenDateRange).map(([scope, co2eEntry]) => {
                            return {
                                label: 'Scope ' + scope,
                                data: Object.values(co2eEntry),
                                backgroundColor: getChartColor(scope - 1)
                            }
                        })
                    }}
                    options={{plugins: {title: {display: true, text: 'GHG Protocol Scope (kg CO₂e)'}}}}
                />

                <h3>Carbon Emissions Scope {latestDateRange.endYear} Summary</h3>

                <table className="carbon-emission-summary">
                    <tr>
                        <th>Scope</th>
                        <th>Carbon emission source</th>
                        <th>Input data</th>
                        <th>Input data unit</th>
                        <th>Carbon emissions (kg CO<sub>2</sub>e)</th>
                    </tr>

                    <tr>
                        <th>1</th>
                        <td>Natural gas used for company facilities</td>
                        <td>{getInput(1, latestDateRangeIndex)}</td>
                        <td>kWh</td>
                        <td>{emissionSummaryCo2es[0]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Company fleet & leased vehicles</td>
                        <td>{getInputSum(124, 153, latestDateRangeIndex)}</td>
                        <td>km</td>
                        <td>{emissionSummaryCo2es[1]}</td>
                    </tr>

                    <tr className="shaded">
                        <th>2</th>
                        <td>Electricity used for company facilities</td>

                        <td>
                            {(parseFloat(getInput(0, latestDateRangeIndex))
                                + parseFloat(getInput(4, latestDateRangeIndex))).toFixed(3)}
                        </td>
                        <td>kWh</td>
                        <td>{emissionSummaryCo2es[2]}</td>
                    </tr>

                    <tr className="shaded">
                        <td></td>
                        <td>Private vehicles for business use</td>
                        <td>{getInputSum(108, 123, latestDateRangeIndex)}</td>
                        <td>km</td>
                        <td>{emissionSummaryCo2es[3]}</td>
                    </tr>

                    <tr>
                        <th>3</th>
                        <td>Business travel</td>
                        <td>{getInputSum(14, 53, latestDateRangeIndex)}</td>
                        <td>km</td>
                        <td>{emissionSummaryCo2es[4]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Staff commute</td>
                        <td>{getInputSum(68, 107, latestDateRangeIndex)}</td>
                        <td>km</td>
                        <td>{emissionSummaryCo2es[5]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Freighting goods</td>
                        <td>{getInputSum(54, 67, latestDateRangeIndex)}</td>
                        <td>km</td>
                        <td>{emissionSummaryCo2es[6]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Water (consumption and wastewater)</td>
                        <td>{getInputSum(8, 9, latestDateRangeIndex)}</td>
                        <td>m³</td>
                        <td>{emissionSummaryCo2es[7]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Waste (landfill, energy, recycling, composting)</td>
                        <td>{getInputSum(10, 13, latestDateRangeIndex)}</td>
                        <td>tonnes</td>
                        <td>{emissionSummaryCo2es[8]}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>Refrigerants</td>
                        <td>{getInputSum(154, 160, latestDateRangeIndex)}</td>
                        <td>kg</td>
                        <td>{emissionSummaryCo2es[9]}</td>
                    </tr>

                    <tr className="row-total">
                        <th colspan="4">Total gross CO<sub>2</sub> emissions (kg CO<sub>2</sub>e)</th>

                        <td>
                            {emissionSummaryCo2es.reduce(
                                (acc, currentVal) => acc + parseFloat(currentVal),
                                0
                            ).toFixed(3)}
                        </td>
                    </tr>
                </table>
            </div>

            <PdfButton generatePdfCallback={generatePdfCallback} />
        </div>
    );
};

export default Results;
