import React, { useState } from 'react';

const FileInput = ({ types, inputChangeCallback }) => {
    const [fileName, setFileName] = useState('');

    const handleChange = ({ target }) => {
        setFileName('Loading...');

        const reader = new FileReader();

        reader.onload = () => {
            setFileName(target.files[0].name);
            inputChangeCallback(reader.result);
        };

        reader.readAsDataURL(target.files[0]);
    };

    return (
        <div className="file-input-container">
            <label className="file-input">
                <input type="file" accept={types.join(', ')} onChange={handleChange} />
            </label>

            {fileName ? <p className="file-name">{fileName}</p> : ''}
        </div>
    );
};

export default FileInput;
