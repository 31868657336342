import React from 'react';
import CarbonCalcForm from './CarbonCalcForm';
import Dashboard from './Dashboard';

const App = ({ admin }) => {
    if (admin) {
        return <Dashboard />;
    }

    return <CarbonCalcForm />;
};

export default App;
