import React, { useState } from 'react';

const SubmitButton = ({ emailDataToSend, goToNextPage, generatePdfCallback }) => {
    const [loading, setLoading] = useState(false);

    const normalText = 'Submit';
    const loadingText = 'Sending...';
    const failText = 'Submission failed - please contact us';
    const [text, setText] = useState(normalText);

    const handleClick = async () => {
        setLoading(true);
        setText(loadingText);

        //const pdf = await generatePdfCallback(); // Uncomment to send generated PDF to server

        jQuery.post({
            dataType: 'text',
            url: jdsAjax.ajaxUrl,
            data: {
                action: 'send_form_data',
                jds_form_data: emailDataToSend,
                //jds_results_pdf: pdf.output('datauristring') // Uncomment to send generated PDF to server
            },
            success: (response) => {
                if ('OK' === response) {
                    goToNextPage();
                } else {
                    setText(failText);
                }
            }
        });
    };

    return (
        <button 
            className="button-submit"
            style={loading ? {color: '#fff8', cursor: 'default'} : {}}
            onClick={handleClick}
            disabled={loading}
        >
            {text}
        </button>
    );
};

export default SubmitButton;
