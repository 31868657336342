import React, { useState } from 'react';

const PdfButton = ({ generatePdfCallback }) => {
    const normalText = 'Download as PDF';
    const loadingText = 'Generating...';
    const loadingStyle = {color: '#fff8', cursor: 'default'};
    const [text, setText] = useState(normalText);
    const [style, setStyle] = useState({});
    const [disabled, setDisabled] = useState(false);

    const generatePdf = async () => {
        setText(loadingText);
        setStyle(loadingStyle);
        setDisabled(true);

        const pdf = await generatePdfCallback();

        pdf.save('results.pdf');
        setText(normalText);
        setStyle({});
        setDisabled(false);
    };

    return (
        <button className="button-pdf" style={style} disabled={disabled} onClick={generatePdf}>{text}</button>
    );
}

export default PdfButton;
