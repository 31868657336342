import React, { useState, Children, cloneElement } from 'react';
import StepIndicator from '../../components/form/StepIndicator';

const Form = ({
    children,
    updateCo2esCallback,
    updateEmailDataCallback,
    deleteCo2esCallback,
    emailDataToSend,
    dateRanges,
    updateCfsCallback
}) => {
    const [_emailData, setEmailData] = useState({});

    const updateEmailData = (stepTitle, stepFormComponentId, stepEmailData) => {
        setEmailData((prevEmailData) => {
            prevEmailData[stepFormComponentId] = {title: stepTitle, data: stepEmailData};
            updateEmailDataCallback(prevEmailData);

            return prevEmailData;
        });
    };

    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const goToNextStep = () => {
        setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1);
    };

    const goToPrevStep = () => {
        setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex - 1);
    };

    const jumpToStep = (step) => {
        setActiveStepIndex(step);
    };

    const numSteps = Children.toArray(children).length;

    const renderChildren = () => {
        return Children.map(children, (child, i) => {
            const propsToUpdate = {
                active: activeStepIndex === i,
                first: 0 === i,
                goToNextStep: goToNextStep,
                goToPrevStep: goToPrevStep,
                updateCo2esCallback: updateCo2esCallback,
                deleteCo2esCallback: deleteCo2esCallback,
                updateEmailDataCallback: updateEmailData,
                dateRanges: dateRanges,
                formComponentId: i.toString(),
                emailDataToSend: emailDataToSend,
                updateCfsCallback: updateCfsCallback
            };

            return cloneElement(child, propsToUpdate);
        });
    };

    return (
        <div className="form">
            <StepIndicator step={activeStepIndex} totalSteps={numSteps} clickHandler={jumpToStep} />
            {renderChildren()}
        </div>
    );
};

export default Form;
