import React, { useState, Children, cloneElement } from 'react';

const FormSection = ({ 
    children,
    title,
    firstInRepeat = false,
    handleClickRemoveRepeatButton,
    removeRepeatButtonText,
    formComponentId,
    updateSummaryCallback,
    updateDateRangeCallback,
    updateEmailDataCallback,
    updateMultiplierCallback,
    updateCfCallback
}) => {
    const [_summary, setSummary] = useState({});

    const updateSummary = (aggIndex, scope, val) => {
        if (updateSummaryCallback) {
            setSummary((prevSummary) => {
                prevSummary[aggIndex] = {scope: scope, val: val};
    
                updateSummaryCallback(prevSummary);
                
                return prevSummary;
            });
        }
    };

    const [_emailData, setEmailData] = useState({});

    const updateEmailData = (fieldTitle, fieldFormComponentId, inputVal) => {
        setEmailData((prevEmailData) => {
            prevEmailData[fieldFormComponentId] = {title: fieldTitle, value: inputVal};
            updateEmailDataCallback(prevEmailData, title, formComponentId);

            return prevEmailData;
        });
    }

    const renderRemoveRepeatButtonContainer = () => {
        return (
            <div className="repeat-button-container remove-repeat-button-container">
                <button onClick={handleClickRemoveRepeatButton}>{removeRepeatButtonText}</button>
            </div>
        );
    }

    const renderChildren = () => {
        return Children.map(children, (child, i) => {
            return cloneElement(
                child, 
                {
                    updateSummaryCallback: updateSummary,
                    updateDateRangeCallback: updateDateRangeCallback,
                    updateEmailDataCallback: updateEmailData,
                    updateMultiplierCallback: updateMultiplierCallback,
                    formComponentId: formComponentId.toString() + '-' + i.toString(),
                    updateCfCallback: updateCfCallback
                }
            );
        });
    };

    return (
        <div className="form-section">
            <div className="heading">
                {title ? <h3>{title}</h3> : ''}
                {firstInRepeat ? renderRemoveRepeatButtonContainer() : ''}
            </div>
            
            <div className="fields">{renderChildren()}</div>
        </div>
    );
};

export default FormSection;
