import React, { useState } from 'react';

const SelectInput = ({ children, placeholderText, changeHandler }) => {
    const unselectedVal = "unselected";
    const [val, setVal] = useState(unselectedVal);

    const handleChange = ({ target }) => {
        setVal(target.value);
        changeHandler(target.value);
    };

    return (
        <select
            className={unselectedVal === val ? "unselected" : ""}
            value={val}
            onChange={handleChange}
        >
            <option value={unselectedVal} disabled selected>{placeholderText}</option>
            {children}
        </select>
    );
};

export default SelectInput;
