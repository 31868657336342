import React, { useState, Children, cloneElement } from 'react';

const FormStep = ({
    children,
    title,
    active,
    first,
    goToNextStep,
    goToPrevStep,
    formComponentId,
    updateCo2esCallback,
    deleteCo2esCallback,
    updateEmailDataCallback,
    emailDataToSend,
    dateRanges,
    updateCfsCallback
}) => {
    const [_emailData, setEmailData] = useState({});

    const updateEmailData = (emailData, sectionTitle, sectionFormComponentId) => {
        setEmailData((prevEmailData) => {
            if (sectionTitle && sectionFormComponentId) {
                prevEmailData[sectionFormComponentId] = {title: sectionTitle, data: emailData};
            } else {
                Object.entries(emailData).forEach(([sectionFormComponentId, emailData]) => {
                    let sectionTitle = emailData.title;
    
                    if (emailData.dateRangeIndex >= 0) {
                        sectionTitle = appendDateRange(sectionTitle, emailData.dateRangeIndex);
                    }
        
                    prevEmailData[sectionFormComponentId] = {title: sectionTitle, data: emailData.data};
                });
            }

            updateEmailDataCallback(title, formComponentId, prevEmailData);

            return prevEmailData;
        });
    };

    const deleteEmailData = (sectionFormComponentIds) => {
        setEmailData((prevEmailData) => {
            sectionFormComponentIds.forEach((sectionFormComponentId) => {
                delete prevEmailData[sectionFormComponentId];
            });

            updateEmailDataCallback(title, formComponentId, prevEmailData);

            return prevEmailData;
        });
    };

    const appendDateRange = (text, dateRangeIndex) => {
        if (dateRanges[dateRangeIndex]) {
            return text + ' (' + dateRanges[dateRangeIndex].name + ')';
        }
        
        return text;
    }

    const [activePageIndex, setActivePageIndex] = useState(0);

    const handleClickNext = () => {
        setActivePageIndex((prevActivePageIndex) => {
            if (Children.toArray(children).length - 1 === prevActivePageIndex) {
                goToNextStep();

                return prevActivePageIndex;
            }

            return prevActivePageIndex + 1;
        });
    };

    const handleClickPrev = () => {
        setActivePageIndex((prevActivePageIndex) => {
            if (0 === prevActivePageIndex) {
                goToPrevStep();

                return prevActivePageIndex;
            }

            return prevActivePageIndex - 1;
        });
    };

    const renderChildren = () => {
        return Children.map(children, (child, i) => {
            const propsToUpdate = {
                clickNextHandler: handleClickNext,
                clickPrevHandler: handleClickPrev,
                active: active && activePageIndex === i,
                first: first && 0 === i,
                updateCo2esCallback: updateCo2esCallback,
                deleteCo2esCallback: deleteCo2esCallback,
                updateEmailDataCallback: updateEmailData,
                deleteEmailDataCallback: deleteEmailData,
                formComponentId: formComponentId.toString() + '-' + i.toString(),
                updateCfsCallback: updateCfsCallback
            };

            if (emailDataToSend) {
                propsToUpdate.emailDataToSend = emailDataToSend;
            }

            return cloneElement(child, propsToUpdate);
        });
    };

    return (
        <div className="form-step">
            {renderChildren()}
        </div>
    );
};

export default FormStep;
