import React from 'react';
import DateRangeEditor from './components/dashboard/DateRangeEditor';
import EmailEditor from './components/dashboard/EmailEditor';

const Dashboard = () => {
    return (
        <>
            <h1>Carbon Calculator Form</h1>
            <DateRangeEditor />
            <EmailEditor />
        </>
    );
};

export default Dashboard;
