import React from 'react';

const NumberInput = ({
    placeholderText,
    minVal,
    maxVal = Number.MAX_SAFE_INTEGER,
    changeHandler,
    classNames = [],
    inputChangeCallback
}) => {
    const handleChange = ({ target }) => {
        inputChangeCallback(target.value, false, placeholderText);

        if (changeHandler) {
            changeHandler(target.value);
        }
    };

    return <input
        type="number"
        className={classNames.join(" ")}
        placeholder={placeholderText}
        min={minVal}
        max={maxVal}
        onChange={handleChange} 
    />;
};

export default NumberInput;
