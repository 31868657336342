import React, { Children, cloneElement } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SubmitButton from '../../components/form/SubmitButton';

const FormPage = ({
    children,
    title,
    clickNextHandler,
    clickPrevHandler,
    active = false,
    first = false,
    displayNav = true,
    isSubmissionPage = false,
    formComponentId,
    updateCo2esCallback,
    deleteCo2esCallback,
    updateEmailDataCallback,
    deleteEmailDataCallback,
    emailDataToSend,
    updateCfsCallback
}) => {
    const pdfPageIds = [
        'jds-sq-carboncalc-results-1',
        'jds-sq-carboncalc-results-2',
        'jds-sq-carboncalc-results-3'
    ];

    const generatePdf = async () => {
        const canvasPromises = [];

        pdfPageIds.forEach((id) => {
            canvasPromises.push(html2canvas(document.getElementById(id), {scale: 1.5}))
        });

        const canvases = await Promise.all(canvasPromises);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 25;
        const logoPath = '../wp-content/plugins/sq-carboncalc/images/sq-logo.png';
        const logoWidth = 18;
        const logoRatio = 0.967;

        canvases.forEach((canvas, i) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;

            const ratio = imgWidth / imgHeight >= (pageWidth - 2 * margin) / (pageHeight - 2 * margin) ? 
                (pageWidth - 2 * margin) / imgWidth :
                (pageHeight - 2 * margin) / imgHeight;

            if (0 !== i) {
                pdf.addPage();
            }

            pdf.setFillColor('#FFFFFF');
            pdf.rect(0, 0, pageWidth, pageHeight, 'F');

            if (0 === i) {
                pdf.addImage(logoPath, 'PNG', margin, margin, logoWidth, logoWidth * logoRatio);
            }

            pdf.addImage(
                canvas.toDataURL("image/png"),
                'PNG',
                margin,
                0 === i ? margin + logoWidth * logoRatio + 4 : margin,
                imgWidth * ratio,
                imgHeight * ratio
            );
        });

        return pdf;
    }

    const renderChildren = () => {
        return Children.map(children, (child, i) => {
            return cloneElement(
                child,
                {
                    updateCo2esCallback: updateCo2esCallback,
                    deleteCo2esCallback: deleteCo2esCallback,
                    updateEmailDataCallback: updateEmailDataCallback,
                    deleteEmailDataCallback: deleteEmailDataCallback,
                    pdfPageIds: pdfPageIds,
                    generatePdfCallback: generatePdf,
                    formComponentId: formComponentId.toString() + '-' + i.toString(),
                    updateCfsCallback: updateCfsCallback
                }
            );
        });
    };

    const renderNav = () => {
        return (
            <div className="container-nav-buttons">
                {!first ? <button className="button-prev" onClick={clickPrevHandler}>Previous</button> : ''}

                {
                    isSubmissionPage ? 
                        <SubmitButton
                            emailDataToSend={emailDataToSend}
                            goToNextPage={clickNextHandler}
                            generatePdfCallback={generatePdf}
                        /> :
                        <button className="button-next" onClick={clickNextHandler}>Next</button>
                }
            </div>
        );
    };

    return (
        <div className={'form-page' + (active ? ' active' : '')}>
            <div>
                <h2>{title}</h2>
                {renderChildren()}
            </div>

            {displayNav ? renderNav() : ''}
        </div>
    );
};

export default FormPage;
